/* eslint-disable */
const {
    REACT_APP_API_URL_STAGING, REACT_APP_API_URL_PROD, REACT_APP_ENV, REACT_APP_API_URL_DEV, REACT_APP_API_URL_LOCAL,
    REACT_APP_URL_STAGING, REACT_APP_URL_PROD, REACT_APP_URL_DEV, REACT_APP_URL_LOCAL,
    REACT_APP_API_REPORT_URL_DEV, REACT_APP_API_REPORT_URL_PROD, REACT_APP_API_REPORT_URL_STAGING_VPN, REACT_APP_API_REPORT_URL_STAGING, REACT_APP_API_REPORT_URL,
    REACT_APP_SYSTEM_TITLE, REACT_APP_SYSTEM_NAME, REACT_APP_SYSTEM_CODE, REACT_APP_SYSTEM_SCODE, REACT_APP_SYSTEM_VERSION
} = process.env;

const apiBaseURL = (REACT_APP_ENV === "local" ? REACT_APP_API_URL_DEV : (REACT_APP_ENV === "production" ? REACT_APP_API_URL_PROD : (REACT_APP_ENV === "staging" ? REACT_APP_API_URL_STAGING : (REACT_APP_ENV === "development" ? REACT_APP_API_URL_DEV : REACT_APP_API_URL_DEV))));
const baseURL = (REACT_APP_ENV === "local" ? REACT_APP_URL_LOCAL : (REACT_APP_ENV === "production" ? REACT_APP_URL_PROD : (REACT_APP_ENV === "staging" ? REACT_APP_URL_STAGING : (REACT_APP_ENV === "development" ? REACT_APP_URL_DEV : REACT_APP_URL_LOCAL))));
const envName = (REACT_APP_ENV === "local" ? REACT_APP_URL_LOCAL : (REACT_APP_ENV === "production" ? "" : (REACT_APP_ENV === "staging" ? " - UAT" : (REACT_APP_ENV === "development" ? " - DEV" : " - DEV"))));
const systemEnv = (REACT_APP_ENV === "local" ? "Local" : (REACT_APP_ENV === "production" ? "Prod" : (REACT_APP_ENV === "staging" ? "Staging" : (REACT_APP_ENV === "development" ? "Develop" : "Develop"))));


const apiReportURL =
    REACT_APP_ENV === 'local'
        ? REACT_APP_API_URL_LOCAL
        : REACT_APP_ENV === 'production'
            ? REACT_APP_API_REPORT_URL_PROD
            : REACT_APP_ENV === 'staging'
                ? window.location.hostname === '10.8.0.166'
                    ? REACT_APP_API_REPORT_URL_STAGING_VPN
                    : REACT_APP_API_REPORT_URL_STAGING
                : REACT_APP_ENV === 'development'
                    ? REACT_APP_API_REPORT_URL_DEV
                    : REACT_APP_API_REPORT_URL;


const systemTitle = REACT_APP_SYSTEM_TITLE;
const systemName = REACT_APP_SYSTEM_NAME;
const systemCode = REACT_APP_SYSTEM_CODE;
const systemSCode = REACT_APP_SYSTEM_SCODE;
const systemVersion = REACT_APP_SYSTEM_VERSION;


export { apiBaseURL, baseURL, envName, systemEnv, apiReportURL, systemTitle, systemName, systemCode, systemSCode, systemVersion };