import React, { useMemo } from 'react';
import { Row, Col } from 'react-bootstrap';
import className from 'classnames';
import serviceList from 'data/feature/serviceList';
import Section from 'components/common/Section';
import CardService from './CardService';
import SectionHeader from './SectionHeader';
import { baseURL } from 'common-helpers/env-common';
import { useVerifyEmail } from 'hooks/useVerifyEmail';
const { REACT_APP_ENV } = process.env;
import { useSystemAuthorization } from 'common-hooks/hooks/useSystemAuthorization';

const Services = ({ verifyCode }) => {

  const isLocalhost = () => {
    return (
      window.location.hostname === 'localhost' ||
      window.location.hostname === '127.0.0.1'
    );
  };

  //const systemAuthorization = useSystemAuthorization();
  const verifyEmail = useVerifyEmail();
  // useMemo(() => {
  //   systemAuthorization.onGetVerifyAccount();
  // }, [])


  useMemo(() => {
    (async () => {

      const response = await verifyEmail.onGetVerifyEmail(verifyCode);

      // console.log(' Verify Email >>>', response);
      setTimeout(() => {
        window.location.href = `${REACT_APP_ENV == 'development' ? (isLocalhost() ? '' : baseURL) : baseURL}/landing?ts=${Date.now()}`;
      }, 3000);

      // const resp = await systemAuthorization.onGetVerifyAccount();
      // console.log(' useMemo data >> ', resp);

      //const resp = await systemAuthorization.onGetVerifyAccount();
      //console.log('resp >> ', resp.data);

    })();
  }, []);
  //const verifyEmail = useVerifyEmail();

  // useMemo(() => {
  //   (async () => {
  //     //console.log('xxxxx');
  //     const response = await verifyEmail.onGetVerifyEmail(verifyCode);
  //     console.log(' Verify Email >>>', response);

  //     // setTimeout(() => {
  //     //   window.location.href = `${REACT_APP_ENV == 'development' ? (isLocalhost() ? '' : baseURL) : baseURL}/landing?ts=${Date.now()}`;
  //     // }, 3000);

  //   })();

  // }, []);

  return (
    <Section bg="light" className="text-center">
      <SectionHeader
        title={`Verify Successful.(${verifyCode})`}
        subtitle="Things you will get right out of the box with Syscore."
      />
      <Row className="mt-6">
        {/* {verifyCode} */}
        {/* {serviceList.map((service, index) => (
        <Col
          lg={4}
          className={className({ 'mt-6 mt-lg-0': index > 0 })}
          key={index}
        >

          <CardService {...service} />
        </Col>
      ))} */}
      </Row>
    </Section>
  )
};

export default Services;
