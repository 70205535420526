/* eslint-disable */
import { fetchWrapper, apiBaseURL } from 'common-helpers';

export { useVerifyEmail };
const baseModuleApi = 'auth0/api';
const baseApi = 'authenticate';
const waitingTime = 500;

function useVerifyEmail() {
    const baseUrl = `${apiBaseURL}`; //`https://nongpho-sit.rfl999.com/apis`
    return {
        onGetVerifyEmail
    };


    ///stock-onhands/77d02a9a-1eb1-4376-a8b7-0316ddac5808/wh/e53843f8-baa2-4c3f-8131-e30d0f4a998f/e8f7336f-55cc-4e56-ab99-a172ab877ad9/product
    async function getVerifyEmail(verifyCode) {
        //setLoading(true);
        //let ss = `${baseUrl}/${baseModuleApi}/${baseApi}/verify-email/${verifyCode}`;
        //console.log('mm >>', ss)

        return fetchWrapper
            .get(`${baseUrl}/${baseModuleApi}/${baseApi}/verify-email/${verifyCode}`)
            .then(response => {
                //console.log(' getVerifyEmail > ', response);
                // setTotalRecord(1);
                setTimeout(() => {
                    // setLoading(false);
                }, waitingTime);
                return response?.data;
            });
    }


    async function onGetVerifyEmail(verifyCode) {
        return await getVerifyEmail(verifyCode);
    }


}
