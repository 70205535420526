import React from 'react';
import CustomerDetailsHeader from './CustomerDetailsHeader';
import CustomerInfo from './CustomerInfo';
import CustomerLog from './CustomerLog';

const CustomersDetails = () => {
  return (
    <>
      <CustomerDetailsHeader />
      <CustomerInfo />
      <CustomerLog />
    </>
  );
};

export default CustomersDetails;
