import React from 'react';
import { Card } from 'react-bootstrap';
import SyscoreCardHeader from 'components/common/SyscoreCardHeader';
import SyscoreLink from 'components/common/SyscoreLink';
import SalesPosLocationTable from './SalesPosLocationTable';
import SalesPosLocationChart from './SalesPosLocationChart';

const SalesPosLocation = ({ ...rest }) => {
  return (
    <Card {...rest}>
      <SyscoreCardHeader
        title="Sales by POS location"
        titleTag="h6"
        className="py-2"
        light
        endEl={<SyscoreLink title="View details" className="px-0 " />}
      />
      <Card.Body className="px-0 pb-0">
        <SalesPosLocationTable />
        <SalesPosLocationChart />
      </Card.Body>
    </Card>
  );
};

export default SalesPosLocation;
