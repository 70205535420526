import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, Row, Col } from 'react-bootstrap';
import Divider from 'components/common/Divider';
import SocialAuthButtons from './SocialAuthButtons';
import AuthContext from 'common-helpers/AuthContext';




const RegistrationForm = ({ hasLabel }) => {
  // State
  const [formData, setFormData] = useState({
    firstName: '',
    lastname: '',
    userName: '',
    email: '',
    password: '',
    confirmPassword: '',
    isAccepted: false
  });

  const { register } = useContext(AuthContext)

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    // toast.success(`Successfully registered as ${formData.firstName}`, {
    //   theme: 'colored'
    // });

    (async () => {
      //console.log('regsiter data >>', formData);
      // let payload = {
      //   username: formData.userName,
      //   password: formData.password
      // }
      if (validateEmail(formData.email)) {
        await register(formData);
      } else {
        toast.error(`Invalid email ${formData.email} !!!`, {
          theme: 'colored'
        });
      }
    })();

  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row className="g-1 mb-1">
        <Form.Group className="mb-1" as={Col} sm={6}>
          {hasLabel && <Form.Label>FirstName</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'FirstName' : ''}
            value={formData.firstName}
            name="firstName"
            onChange={handleFieldChange}
            type="text"
          />
        </Form.Group>

        <Form.Group className="mb-1" as={Col} sm={6}>
          {hasLabel && <Form.Label>LastName</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'LastName' : ''}
            value={formData.lastName}
            name="lastName"
            onChange={handleFieldChange}
            type="text"
          />
        </Form.Group>
      </Row>
      <Row className="g-1 mb-1">
        <Form.Group className="mb-1" as={Col} sm={12}>
          {hasLabel && <Form.Label>Username</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Username' : ''}
            value={formData.userName}
            name="userName"
            onChange={handleFieldChange}
            type="text"
          />
        </Form.Group>
      </Row>
      <Row className="g-1 mb-1">
        <Form.Group className="mb-1" as={Col} sm={12}>
          {hasLabel && <Form.Label>Email address</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Email address' : ''}
            value={formData.email}
            name="email"
            onChange={handleFieldChange}
            type="text"
          />
        </Form.Group>
      </Row>

      <Row className="g-1 mb-3">
        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>Password</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Password' : ''}
            value={formData.password}
            name="password"
            onChange={handleFieldChange}
            type="password"
          />
        </Form.Group>
        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>Confirm Password</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Confirm Password' : ''}
            value={formData.confirmPassword}
            name="confirmPassword"
            onChange={handleFieldChange}
            type="password"
          />
        </Form.Group>
      </Row>

      <Form.Group className="mb-3">
        <Form.Check type="checkbox" id="acceptCheckbox" className="form-check">
          <Form.Check.Input
            type="checkbox"
            name="isAccepted"
            checked={formData.isAccepted}
            onChange={e =>
              setFormData({
                ...formData,
                isAccepted: e.target.checked
              })
            }
          />
          <Form.Check.Label className="form-label">
            I accept the <Link to="#!">terms</Link> and{' '}
            <Link to="#!">privacy policy</Link>
          </Form.Check.Label>
        </Form.Check>
      </Form.Group>

      <Form.Group className="mb-4">
        <Button
          className="w-100"
          type="submit"
          disabled={
            (!formData.firstName ||
              !formData.lastName ||
              !formData.userName ||
              !formData.email ||
              !formData.password ||
              !formData.confirmPassword ||
              !formData.isAccepted) || !(formData.password == formData.confirmPassword)
          }
        >
          Register
        </Button>
      </Form.Group>
      {/* <Divider>or register with</Divider> */}

      {/* <SocialAuthButtons /> */}
    </Form>
  );
};

RegistrationForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default RegistrationForm;
