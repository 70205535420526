/* eslint-disable */
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { baseURL, apiBaseURL } from 'common-helpers/env-common';
const { REACT_APP_ENV } = process.env;
import AuthContext from './AuthContext';

const scode = '';// '/es205';

const isLocalhost = () => {
  return (
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1'
  );
};


const ProtectedRoute = ({ children, accessBy }) => {
  const { user } = useContext(AuthContext);

  if (accessBy == 'non-authenticated') {
    if (!user) {
      return children;
    }
  } else if (accessBy === 'authenticated') {
    if (user) {
      return children;
    }
  }

  let url = `${REACT_APP_ENV == 'development' && isLocalhost() ? `${scode}/landing` : baseURL + `${scode}/landing`}/?tsx=${Date.now()}`;
  window.location.href = url;

  //console.log(`ProtectedRoute URL: >> `, url)

  // window.location.href = `${
  //   REACT_APP_ENV == 'development' ? (isLocalhost() ? '/' : baseURL) : baseURL
  // }/?tsx=${Date.now()}`;


  //window.location.href = `${REACT_APP_ENV == 'local' ? '/landing' : (REACT_APP_ENV == 'local' ? '' : baseURL)}/?ts=${Date.now()}`;
  //window.location.href = `${REACT_APP_ENV == 'development' && isLocalhost() ? `${scode}/landing` : baseURL + `${scode}/landing`}/?tsx=${Date.now()}`;
  //window.location.href = `${REACT_APP_ENV == 'development' ? (isLocalhost() ? `${scode}/landing` : baseURL + `${scode}/landing`) : baseURL + `${scode}/landing`}/?tsx=${Date.now()}`;
  // return <Navigate to="/landing"></Navigate>;
};

export default ProtectedRoute;
