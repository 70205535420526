import { v4 as uuid } from 'uuid';
import moment from 'moment';

const today = new Date().getDate().toString().padStart(2, '0');

let currentMonth = (new Date().getMonth() + 1).toString().padStart(2, '0');
const nextMonth = (currentMonth + 1).toString().padStart(2, '0');
const prevMonth = (currentMonth - 1).toString().padStart(2, '0');
const currentYear = new Date().getUTCFullYear();

const events = [
  {
    // id: uuid(),
    title: 'ทำนมรสสตรอเบอร์รี่ 500 กล่อง',
    start: `${currentYear}-${currentMonth}-01 09:00:00`,
    end: `${currentYear}-${currentMonth}-01 18:00:00`,
    description: '',
    className: 'bg-soft-info',
    location: 'สหกรณ์โคนมหนองโพราชบุรี จำกัด'
    // organizer: 'Boston Harbor Now'
  },
  {
    // id: uuid(),
    title: 'ทำนมรสจืด 2000 กล่อง',
    start: `${currentYear}-${currentMonth}-01 09:00:00`,
    end: `${currentYear}-${currentMonth}-01 18:00:00`,
    description: '',
    className: 'bg-soft-info',
    location: 'สหกรณ์โคนมหนองโพราชบุรี จำกัด'
    // organizer: 'Boston Harbor Now'
  },
  {
    // id: uuid(),
    title: 'ทำนมรสสตรอเบอร์รี่ & รสจืด 2000 กล่อง',
    start: `${currentYear}-${currentMonth}-02 09:00:00`,
    end: `${currentYear}-${currentMonth}-02 18:00:00`,
    description: '',
    className: 'bg-soft-info',
    location: 'สหกรณ์โคนมหนองโพราชบุรี จำกัด'
    // organizer: 'Boston Harbor Now'
  },
  {
    // id: uuid(),
    title: 'ทำนมรสจืด 1500 กล่อง',
    start: `${currentYear}-${currentMonth}-03 09:00:00`,
    end: `${currentYear}-${currentMonth}-03 18:00:00`,
    description: '',
    className: 'bg-soft-info',
    location: 'สหกรณ์โคนมหนองโพราชบุรี จำกัด'
    // organizer: 'Boston Harbor Now'
  },
  {
    // id: uuid(),
    title: 'ทำนมรสจืด 2000 กล่อง',
    start: `${currentYear}-${currentMonth}-04 09:00:00`,
    end: `${currentYear}-${currentMonth}-04 18:00:00`,
    description: '',
    className: 'bg-soft-info',
    location: 'สหกรณ์โคนมหนองโพราชบุรี จำกัด'
    // organizer: 'Boston Harbor Now'
  },
  {
    // id: uuid(),
    title: 'ทำนมรสสตรอเบอร์รี่ & รสจืด 2500 กล่อง',
    start: `${currentYear}-${currentMonth}-07 09:00:00`,
    end: `${currentYear}-${currentMonth}-07 18:00:00`,
    description: '',
    className: 'bg-soft-info',
    location: 'สหกรณ์โคนมหนองโพราชบุรี จำกัด'
    // organizer: 'Boston Harbor Now'
  },
  {
    // id: uuid(),
    title: 'ทำนมรสสตรอเบอร์รี่ & รสจืด 2500 กล่อง',
    start: `${currentYear}-${currentMonth}-07 09:00:00`,
    end: `${currentYear}-${currentMonth}-07 18:00:00`,
    description: '',
    className: 'bg-soft-info',
    location: 'สหกรณ์โคนมหนองโพราชบุรี จำกัด'
    // organizer: 'Boston Harbor Now'
  },
  {
    // id: uuid(),
    title: 'ทำนมรสสตรอเบอร์รี่ & รสจืด 3000 กล่อง',
    start: `${currentYear}-${currentMonth}-07 09:00:00`,
    end: `${currentYear}-${currentMonth}-07 18:00:00`,
    description: '',
    className: 'bg-soft-info',
    location: 'สหกรณ์โคนมหนองโพราชบุรี จำกัด'
    // organizer: 'Boston Harbor Now'
  },
  {
    // id: uuid(),
    title: 'ทำนมรสสตรอเบอร์รี่ 2500 กล่อง',
    start: `${currentYear}-${currentMonth}-08 09:00:00`,
    end: `${currentYear}-${currentMonth}-08 18:00:00`,
    description: '',
    className: 'bg-soft-info',
    location: 'สหกรณ์โคนมหนองโพราชบุรี จำกัด'
    // organizer: 'Boston Harbor Now'
  },
  {
    // id: uuid(),
    title: 'ทำนมรสสตรอเบอร์รี่ & รสจืด 5000 กล่อง',
    start: `${currentYear}-${currentMonth}-09 09:00:00`,
    end: `${currentYear}-${currentMonth}-09 18:00:00`,
    description: '',
    className: 'bg-soft-info',
    location: 'สหกรณ์โคนมหนองโพราชบุรี จำกัด'
    // organizer: 'Boston Harbor Now'
  },
  {
    // id: uuid(),
    title: 'ทำนมรสสตรอเบอร์รี่ & รสจืด 3000 กล่อง',
    start: `${currentYear}-${currentMonth}-09 09:00:00`,
    end: `${currentYear}-${currentMonth}-09 18:00:00`,
    description: '',
    className: 'bg-soft-info',
    location: 'สหกรณ์โคนมหนองโพราชบุรี จำกัด'
    // organizer: 'Boston Harbor Now'
  },
  {
    // id: uuid(),
    title: 'ทำนมรสจืด 2500 กล่อง',
    start: `${currentYear}-${currentMonth}-10 09:00:00`,
    end: `${currentYear}-${currentMonth}-10 18:00:00`,
    description: '',
    className: 'bg-soft-info',
    location: 'สหกรณ์โคนมหนองโพราชบุรี จำกัด'
    // organizer: 'Boston Harbor Now'
  },
  {
    // id: uuid(),
    title: 'ทำนมรสจืด 1500 กล่อง',
    start: `${currentYear}-${currentMonth}-11 09:00:00`,
    end: `${currentYear}-${currentMonth}-11 18:00:00`,
    description: '',
    className: 'bg-soft-info',
    location: 'สหกรณ์โคนมหนองโพราชบุรี จำกัด'
    // organizer: 'Boston Harbor Now'
  },
  {
    // id: uuid(),
    title: 'ทำนมรสสตรอเบอร์รี่ & รสจืด 2500 กล่อง',
    start: `${currentYear}-${currentMonth}-11 09:00:00`,
    end: `${currentYear}-${currentMonth}-11 18:00:00`,
    description: '',
    className: 'bg-soft-info',
    location: 'สหกรณ์โคนมหนองโพราชบุรี จำกัด'
    // organizer: 'Boston Harbor Now'
  },
  {
    // id: uuid(),
    title: 'ทำนมรสสตรอเบอร์รี่ & รสจืด 2000 กล่อง',
    start: `${currentYear}-${currentMonth}-14 09:00:00`,
    end: `${currentYear}-${currentMonth}-14 18:00:00`,
    description: '',
    className: 'bg-soft-info',
    location: 'สหกรณ์โคนมหนองโพราชบุรี จำกัด'
    // organizer: 'Boston Harbor Now'
  },
  {
    // id: uuid(),
    title: 'ทำนมรสสตรอเบอร์รี่ & รสจืด 3500 กล่อง',
    start: `${currentYear}-${currentMonth}-15 09:00:00`,
    end: `${currentYear}-${currentMonth}-15 18:00:00`,
    description: '',
    className: 'bg-soft-info',
    location: 'สหกรณ์โคนมหนองโพราชบุรี จำกัด'
    // organizer: 'Boston Harbor Now'
  },
  {
    // id: uuid(),
    title: 'ทำนมรสสตรอเบอร์รี่ 3500 กล่อง',
    start: `${currentYear}-${currentMonth}-16 09:00:00`,
    end: `${currentYear}-${currentMonth}-16 18:00:00`,
    description: '',
    className: 'bg-soft-info',
    location: 'สหกรณ์โคนมหนองโพราชบุรี จำกัด'
    // organizer: 'Boston Harbor Now'
  },
  {
    // id: uuid(),
    title: 'ทำนมรสจืด 3500 กล่อง',
    start: `${currentYear}-${currentMonth}-16 09:00:00`,
    end: `${currentYear}-${currentMonth}-16 18:00:00`,
    description: '',
    className: 'bg-soft-info',
    location: 'สหกรณ์โคนมหนองโพราชบุรี จำกัด'
    // organizer: 'Boston Harbor Now'
  },
  {
    // id: uuid(),
    title: 'ทำนมรสสตรอเบอร์รี่ & รสจืด 2500 กล่อง',
    start: `${currentYear}-${currentMonth}-16 09:00:00`,
    end: `${currentYear}-${currentMonth}-16 18:00:00`,
    description: '',
    className: 'bg-soft-info',
    location: 'สหกรณ์โคนมหนองโพราชบุรี จำกัด'
    // organizer: 'Boston Harbor Now'
  },
  {
    // id: uuid(),
    title: 'ทำนมรสสตรอเบอร์รี่ & รสจืด 1500 กล่อง',
    start: `${currentYear}-${currentMonth}-17 09:00:00`,
    end: `${currentYear}-${currentMonth}-17 18:00:00`,
    description: '',
    className: 'bg-soft-info',
    location: 'สหกรณ์โคนมหนองโพราชบุรี จำกัด'
    // organizer: 'Boston Harbor Now'
  },
  {
    // id: uuid(),
    title: 'ทำนมรสสตรอเบอร์รี่ 4500 กล่อง',
    start: `${currentYear}-${currentMonth}-18 09:00:00`,
    end: `${currentYear}-${currentMonth}-18 18:00:00`,
    description: '',
    className: 'bg-soft-info',
    location: 'สหกรณ์โคนมหนองโพราชบุรี จำกัด'
    // organizer: 'Boston Harbor Now'
  },
  {
    // id: uuid(),
    title: 'ทำนมรสจืด 3000 กล่อง',
    start: `${currentYear}-${currentMonth}-21 09:00:00`,
    end: `${currentYear}-${currentMonth}-21 18:00:00`,
    description: '',
    className: 'bg-soft-info',
    location: 'สหกรณ์โคนมหนองโพราชบุรี จำกัด'
    // organizer: 'Boston Harbor Now'
  },
  {
    // id: uuid(),
    title: 'ทำนมรสสตรอเบอร์รี่ & รสจืด 2500 กล่อง',
    start: `${currentYear}-${currentMonth}-21 09:00:00`,
    end: `${currentYear}-${currentMonth}-21 18:00:00`,
    description: '',
    className: 'bg-soft-info',
    location: 'สหกรณ์โคนมหนองโพราชบุรี จำกัด'
    // organizer: 'Boston Harbor Now'
  },
  {
    // id: uuid(),
    title: 'ทำนมรสสตรอเบอร์รี่ 2000 กล่อง',
    start: `${currentYear}-${currentMonth}-21 09:00:00`,
    end: `${currentYear}-${currentMonth}-21 18:00:00`,
    description: '',
    className: 'bg-soft-info',
    location: 'สหกรณ์โคนมหนองโพราชบุรี จำกัด'
    // organizer: 'Boston Harbor Now'
  },
  {
    // id: uuid(),
    title: 'ทำนมรสสตรอเบอร์รี่ 2500 กล่อง',
    start: `${currentYear}-${currentMonth}-22 09:00:00`,
    end: `${currentYear}-${currentMonth}-22 18:00:00`,
    description: '',
    className: 'bg-soft-info',
    location: 'สหกรณ์โคนมหนองโพราชบุรี จำกัด'
    // organizer: 'Boston Harbor Now'
  },
  {
    // id: uuid(),
    title: 'ทำนมรสจืด 2600 กล่อง',
    start: `${currentYear}-${currentMonth}-23 09:00:00`,
    end: `${currentYear}-${currentMonth}-23 18:00:00`,
    description: '',
    className: 'bg-soft-info',
    location: 'สหกรณ์โคนมหนองโพราชบุรี จำกัด'
    // organizer: 'Boston Harbor Now'
  },
  {
    // id: uuid(),
    title: 'ทำนมรสสตรอเบอร์รี่ & รสจืด 1500 กล่อง',
    start: `${currentYear}-${currentMonth}-23 09:00:00`,
    end: `${currentYear}-${currentMonth}-23 18:00:00`,
    description: '',
    className: 'bg-soft-info',
    location: 'สหกรณ์โคนมหนองโพราชบุรี จำกัด'
    // organizer: 'Boston Harbor Now'
  },
  {
    // id: uuid(),
    title: 'ทำนมรสสตรอเบอร์รี่ & รสจืด 4000 กล่อง',
    start: `${currentYear}-${currentMonth}-24 09:00:00`,
    end: `${currentYear}-${currentMonth}-24 18:00:00`,
    description: '',
    className: 'bg-soft-info',
    location: 'สหกรณ์โคนมหนองโพราชบุรี จำกัด'
    // organizer: 'Boston Harbor Now'
  },
  {
    // id: uuid(),
    title: 'ทำนมรสสตรอเบอร์รี่ & รสจืด 3500 กล่อง',
    start: `${currentYear}-${currentMonth}-25 09:00:00`,
    end: `${currentYear}-${currentMonth}-25 18:00:00`,
    description: '',
    className: 'bg-soft-info',
    location: 'สหกรณ์โคนมหนองโพราชบุรี จำกัด'
    // organizer: 'Boston Harbor Now'
  },
  {
    // id: uuid(),
    title: 'ทำนมรสสตรอเบอร์รี่ 1000 กล่อง',
    start: `${currentYear}-${currentMonth}-28 09:00:00`,
    end: `${currentYear}-${currentMonth}-28 18:00:00`,
    description: '',
    className: 'bg-soft-info',
    location: 'สหกรณ์โคนมหนองโพราชบุรี จำกัด'
    // organizer: 'Boston Harbor Now'
  },
  {
    // id: uuid(),
    title: 'ทำนมรสจืด 2000 กล่อง',
    start: `${currentYear}-${currentMonth}-29 09:00:00`,
    end: `${currentYear}-${currentMonth}-29 18:00:00`,
    description: '',
    className: 'bg-soft-info',
    location: 'สหกรณ์โคนมหนองโพราชบุรี จำกัด'
    // organizer: 'Boston Harbor Now'
  },
  {
    // id: uuid(),
    title: 'ทำนมรสสตรอเบอร์รี่ 1500 กล่อง',
    start: `${currentYear}-${currentMonth}-29 09:00:00`,
    end: `${currentYear}-${currentMonth}-29 18:00:00`,
    description: '',
    className: 'bg-soft-info',
    location: 'สหกรณ์โคนมหนองโพราชบุรี จำกัด'
    // organizer: 'Boston Harbor Now'
  },
  {
    // id: uuid(),
    title: '',
    start: `${currentYear}-${currentMonth}-30 09:00:00`,
    end: `${currentYear}-${currentMonth}-30 18:00:00`,
    description: '',
    className: 'bg-soft-info',
    location: 'สหกรณ์โคนมหนองโพราชบุรี จำกัด'
    // organizer: 'Boston Harbor Now'
  }
];

export default events;
