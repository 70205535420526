import axios from 'axios';
import { createContext, useState } from 'react';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
//import { systemEnv } from 'config';
//import fetchWrapper from './jwtInterceptor';
// const { REACT_APP_API_URL } = process.env;
import { baseURL, apiBaseURL, systemEnv } from 'common-helpers/env-common';
const { REACT_APP_ENV } = process.env;
import { toast } from 'react-toastify';

const AuthContext = createContext();
const scode = ''; // /es205
//const apiBaseURL = '/apis';



export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    // if (localStorage.getItem("tokens")) {
    //   let tokens = JSON.parse(localStorage.getItem("tokens"));
    //   return jwt_decode(tokens.accessToken);
    // }
    if (Cookies.get(`${systemEnv}tokens`)) {
      let tokens = JSON.parse(Cookies.get(`${systemEnv}tokens`));
      return jwt_decode(tokens.accessToken);
    }
    return null;
  });

  const navigate = useNavigate();

  const isLocalhost = () => {
    return (
      window.location.hostname === 'localhost' ||
      window.location.hostname === '127.0.0.1'
    );
  };

  const login = async payload => {

    //console.log('login >>>', payload);

    const apiResponse = await axios.post(
      `${apiBaseURL}/auth0/api/authenticate/login`,
      payload
    )
      // .then(response => {
      //   toast.success(`Logged in as ${payload.username}`, {
      //     theme: 'colored'
      //   });
      // })
      .catch(error => {
        //console.log(error.response.data.message);
        if (error?.response?.data)
          toast.error(`${error?.response?.data?.title}`, {
            theme: 'colored'
          });
      });

    //console.log('apiResponse>>', apiResponse)

    //console.log('apiResponse >>', apiResponse)
    //localStorage.setItem("tokens", JSON.stringify(apiResponse.data));
    Cookies.set(`${systemEnv}tokens`, JSON.stringify(apiResponse.data), { expires: 7 });
    Cookies.set(`${systemEnv}refreshingData`, JSON.stringify({ isRefreshingToken: false }), { expires: 7 });
    setUser(jwt_decode(apiResponse.data.accessToken));
    //window.location.href = `${REACT_APP_ENV == 'local' ? '/' : `${baseURL}/dashboard?ts=${Date.now()}`}`;

    //window.location.href = `${REACT_APP_ENV == 'development' ? (isLocalhost() ? '' : baseURL) : baseURL}/dashboard?ts=${Date.now()}`;

    toast.success(`Logged in as ${payload.username}`, {
      theme: 'colored'
    });

    //window.location.href = `${REACT_APP_ENV == 'development' ? (isLocalhost() ? '' : baseURL + scode) : baseURL + scode}/dashboard?ts1=${Date.now()}`;
    setTimeout(() => {
      window.location.href = `${REACT_APP_ENV == 'development' ? (isLocalhost() ? '' : baseURL + scode) : baseURL + scode}/dashboard?ts1=${Date.now()}`;
    }, 500);

    //setUser(jwt_decode(apiResponse.data.token));
    //navigate(`/dashboard?ts=${Date.now()}`);
  };
  const logout = async () => {
    // invoke the logout API call, for our NestJS API no logout API
    Cookies.remove(`${systemEnv}tokens`);
    //localStorage.removeItem("tokens");
    setUser(null);
    //window.location.href = `${REACT_APP_ENV == 'local' ? '/landing' : baseURL}/?ts=${Date.now()}`;

    window.location.href = `${REACT_APP_ENV == 'development' ? (isLocalhost() ? '' : baseURL) : baseURL
      }${scode}/landing?ts2=${Date.now()}`;
    //navigate(`/?ts=${Date.now()}`);
  };

  const register = async (payload) => {
    // invoke the logout API call, for our NestJS API no logout API
    Cookies.remove(`${systemEnv}tokens`);
    //localStorage.removeItem("tokens");
    setUser(null);

    const apiResponse = await axios.post(
      `${apiBaseURL}/auth0/api/authenticate/register`,
      payload
    ).then(response => {
      toast.success(`Successfully registered as ${payload.firstName}`, {
        theme: 'colored'
      });

      setTimeout(() => {
        window.location.href = `${REACT_APP_ENV == 'development' ? (isLocalhost() ? '' : baseURL) : baseURL
          }${scode}/landing?ts2=${Date.now()}`;
      }, 1000);

    })
      .catch(error => {
        //console.log(error.response.data.message);
        if (error?.response?.data)
          toast.error(`${error?.response?.data?.message}`, {
            theme: 'colored'
          });
      });

    //console.log('data >>', apiResponse.data);
    //window.location.href = `${REACT_APP_ENV == 'local' ? '/landing' : baseURL}/?ts=${Date.now()}`;

    // window.location.href = `${REACT_APP_ENV == 'development' ? (isLocalhost() ? '' : baseURL) : baseURL
    //   }${scode}/landing?ts2=${Date.now()}`;
    //navigate(`/?ts=${Date.now()}`);
  };
  return (
    <AuthContext.Provider value={{ user, login, logout, register }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
