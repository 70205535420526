import React from 'react';
import Hero from './Hero';
import NavbarStandard from './NavbarStandard';
import Partners from './Partners';
import Processes from './Processes';
import Services from './Services';
import Testimonial from './Testimonial';
import Cta from './Cta';
import FooterStandard from './FooterStandard';
import { useNavigate, useParams } from 'react-router-dom';

const VerifyEmail = () => {
  let { verifyCode } = useParams();
  return (
    <>
      {/* <NavbarStandard /> */}
      {/* <Hero /> */}
      {/* <Partners />
      <Processes /> */}
      <Services verifyCode={verifyCode} />
      {/* <Testimonial /> */}
      {/* <Cta /> */}
      {/* <FooterStandard /> */}
    </>
  );
};

export default VerifyEmail;
